export const EventActivityListEn = [
  {
    year: "2018",
    months: [
      {
        month: "August",
        events: [
          {
            year: "2018",
            month: "August",
            date: "11 August",
            eventName:
              "Lenders Gathering Asetku Explore the World of Digital Financial Service",
            location: "Bengawan Solo, Jakarta",
            partner: "Non",
          },
          {
            year: "2018",
            month: "August",
            date: "20-25 August",
            eventName: "Focus Group Discussion (FGD) with Lenders Surabaya",
            location: "Non",
            partner: "Non",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2018",
            month: "September",
            date: "10-15 September",
            eventName: "Focus Group Discussion (FGD) with Lenders Medan",
            location: "Non",
            partner: "Non",
          },
          {
            year: "2018",
            month: "September",
            date: "10-16 September",
            eventName: "Focus Group Discussion (FGD) with Lenders Bandung",
            location: "Non",
            partner: "Non",
          },
        ],
      },

      {
        month: "December",
        events: [
          {
            year: "2018",
            month: "December",
            date: "15 December",
            eventName: "Challenges & Opportunities in Fintech P2P Lending",
            location: "Surabaya",
            partner: "Non",
          },
        ],
      },
    ],
  },
  {
    year: "2019",
    months: [
      {
        month: "January",
        events: [
          {
            year: "2019",
            month: "January",
            date: "30 January",
            eventName: "Akulaku Sahabatku Conference",
            location: "Patio Jakarta",
            partner: "Akulaku",
          },
        ],
      },
      {
        month: "February",
        events: [
          {
            year: "2019",
            month: "February",
            date: "11 February",
            eventName: "Press Conference Asetku #JelasBerkembang",
            location: "Ritz Carlton Pasific Place, Jakarta",
            partner: "Non",
          },
          {
            year: "2019",
            month: "February",
            date: "20 February",
            eventName: "Fintech Talk Vol.2 By Aftech",
            location: "Satrio Tower, Jakarta",
            partner: "Aftech",
          },
          {
            year: "2019",
            month: "February",
            date: "23 February",
            eventName:
              "Movement to Increase Financial Inclusion in Indonesia with Asetku",
            location: "JW Marriott, Medan",
            partner: "Non",
          },
          {
            year: "2019",
            month: "February",
            date: "27 February",
            eventName: "Challenges and Opportunities in Fintech P2P Lending",
            location: "Binus University International, Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "March",
        events: [
          {
            year: "2019",
            month: "March",
            date: "8 March",
            eventName:
              "Accounting in Revolution 4.0 to Achieve SDGs 2030 at Accounting Fair 2019",
            location: "Universitas Bakrie, Jakarta",
            partner: "Non",
          },
          {
            year: "2019",
            month: "March",
            date: "",
            eventName: "Stronger HIPMI 4.0 Powered by Asetku",
            location: "Marbella Hotel, Bandung",
            partner: "HIPMI",
          },
          {
            year: "2019",
            month: "March",
            date: "16-17 March",
            eventName: "AsetKu Fintech Socialization #JelasBerkembang",
            location: "Bintaro X Change Tangerang - Akulaku KarnavAL",
            partner: "Akulaku",
          },
          {
            year: "2019",
            month: "March",
            date: "30 March",
            eventName:
              "How to Reach Your Financial Goal Faster in Digital Era bersama Finansialku",
            location: "Warunk Upnormal Tebet, Jakarta",
            partner: "Finansialku",
          },
        ],
      },
      {
        month: "April",
        events: [
          {
            year: "2019",
            month: "April",
            date: "10 April",
            eventName: "Becoming a Smart Millennial in the Digital Finance Era",
            location:
              "Gd. Aula Pasca Sarjana Universitas Diponegoro Jl. Hayam Wuruk Semarang",
            partner: "Non",
          },
          {
            year: "2019",
            month: "April",
            date: "13 April",
            eventName: "The Rise of Fintech",
            location: "Atmajaya University",
            partner: "Non",
          },
          {
            year: "2019",
            month: "April",
            date: "25 April",
            eventName: "Powering Indonesia SME's with Technology",
            location: "The Kasablanka - Main Hall. Jakarta",
            partner: "Mekari",
          },
        ],
      },
      {
        month: "May",
        events: [
          {
            year: "2019",
            month: "May",
            date: "2-3 May",
            eventName: "Fintech Days 2019",
            location: "Novotel Hotel Palembang",
            partner: "AFPI",
          },
          {
            year: "2019",
            month: "May",
            date: "8-11 May",
            eventName: "Eid Mubarak With Akulaku #AkuBisaBerbagi",
            location: "Senayan City, Jakarta",
            partner: "Akulaku",
          },
          {
            year: "2019",
            month: "May",
            date: "24-26 May",
            eventName: "ICS (Indonesian Cashless Society) 2019",
            location: "Summarecon Mall Bekasi",
            partner: "Non",
          },
          {
            year: "2019",
            month: "May",
            date: "25 May",
            eventName: "Instagram 3.0 - Zero To Millions",
            location: "Hotel Ibis Style Gajah Mada",
            partner: "Rintisan",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2019",
            month: "June",
            date: "25 June",
            eventName: "CGS-CIMB 13th Annual Indonesia Conference",
            location: "@ Ayana MidPlaza",
            partner: "Non",
          },
          {
            year: "2019",
            month: "June",
            date: "27 June",
            eventName: "How to Plan Finances & Fund in The Digital Era",
            location: "@ C3 Jogjakarta Community",
            partner: "Finansialku",
          },
        ],
      },
      {
        month: "July",
        events: [
          {
            year: "2019",
            month: "July",
            date: "19 July",
            eventName: "Be a Smart User in the Digital Finance Era",
            location: "@ DILo Coworking Space Padang",
            partner: "Non",
          },
          {
            year: "2019",
            month: "July",
            date: "26 July",
            eventName: "Be a Smart User in the Digital Finance Era",
            location: "@ Meet Up Coworking Space Pekanbaru",
            partner: "Non",
          },
        ],
      },
      {
        month: "August",
        events: [
          {
            year: "2019",
            month: "August",
            date: "2 August",
            eventName: "Fintech Fest 2019",
            location: "@ Four Points Manado",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2019",
            month: "September",
            date: "3-5 September",
            eventName: "Fintech Exhibition Fintech Day OJK Seminar",
            location:
              "@ BigMall Samarinda @Aston Samarinda Hotel & Convention Center",
            partner: "AFPI & OJK",
          },
          {
            year: "2019",
            month: "September",
            date: "8 September",
            eventName: "Millenials on Industrial Revolution 4.0",
            location: "@ G2 Fakultas Ekonomi Universitas Negeri Surabaya",
            partner: "Universitas Negeri Surabaya",
          },
          {
            year: "2019",
            month: "September",
            date: "13 September",
            eventName:
              "Socialization to Become a Smart User in the Digital Finance Era",
            location: "@ Bali",
            partner: "Non",
          },
          {
            year: "2019",
            month: "September",
            date: "23-24 September",
            eventName: "Indonesia Fintech Summit & Expo 2019",
            location: "@ Jakarta Convention Center",
            partner: "Aftech",
          },
        ],
      },
      {
        month: "October",
        events: [
          {
            year: "2019",
            month: "October",
            date: "17-20 October",
            eventName: "Finexpo & Sundown Run 2019",
            location: "@ Kota Kasablanka",
            partner: "Non",
          },
        ],
      },
      {
        month: "November",
        events: [
          {
            year: "2019",
            month: "November",
            date: "6-8 November",
            eventName: "Indonesia Fintech Show 2019",
            location: "@ Jakarta Convention Center",
            partner: "Tarsus",
          },
          {
            year: "2019",
            month: "November",
            date: "29 November 2019 - 1 December 2019",
            eventName: "Fintech Exhibition Surabaya 219",
            location: "@Convension Hall, Pakuwon Trade Center",
            partner: "AFPI",
          },
        ],
      },
    ],
  },
  {
    year: "2020",
    months: [
      {
        month: "March",
        events: [
          {
            year: "2020",
            month: "March",
            date: "10 March",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Gunung Jati, Cirebon",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "June",
        events: [
          {
            year: "2020",
            month: "June",
            date: "16 June",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Jambi, Jambi",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "July",
        events: [
          {
            year: "2020",
            month: "July",
            date: "9 July",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Tanjungpura, Pontianak",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "August",
        events: [
          {
            year: "2020",
            month: "August",
            date: "11 August",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Bengkulu, Bengkulu",
            partner: "AFPI",
          },
          {
            year: "2020",
            month: "August",
            date: "25 August",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Halu Oleo, Kendari",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "October",
        events: [
          {
            year: "2020",
            month: "October",
            date: "6 October",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Mataram, Mataram",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "November",
        events: [
          {
            year: "2020",
            month: "November",
            date: "17 November",
            eventName:
              "Indonesian Fintech Facing Recession: Brutal Challange or Great Opportunity?",
            location: "Live via Youtube DailySocial",
            partner: "DailySocial",
          },
          {
            year: "2020",
            month: "November",
            date: "26 November",
            eventName: "AFPI Goes To Campus",
            location: "Bangka Belitung",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "December",
        events: [
          {
            year: "2020",
            month: "December",
            date: "3 December ",
            eventName:
              "AsetKu Literacy Event - The World of P2P Lending and the World of Finance After the New Normal, Various Opportunities Within",
            location: "Jambi",
            partner: "non",
          },
        ],
      },
    ],
  },
  {
    year: "2021",
    months: [
      {
        month: "February",
        events: [
          {
            year: "2021",
            month: "February",
            date: "24 February",
            eventName: "AFPI Goes To Campus",
            location: "Lampung",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "February",
            date: "26 February",
            eventName: "AFPI Goes To Campus",
            location: "Bengkulu",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "April",
        events: [
          {
            year: "2021",
            month: "April",
            date: "30 April",
            eventName: "AFPI Goes To Campus",
            location: "Batam",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "May",
        events: [
          {
            year: "2021",
            month: "May",
            date: "28 May",
            eventName: "AFPI Goes To Campus",
            location: "Ambon",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "May",
            date: "31 May",
            eventName: "AFPI Goes To Campus",
            location: "Salatiga",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "May",
            date: "",
            eventName: "CSR 1 Project Month of Eid Mubarak 2021 | Mei 2021",
            location: "Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2021",
            month: "June",
            date: "30 June",
            eventName: "AFPI Goes To Campus",
            location: "Jember",
            partner: "AFPI",
          },
        ],
      },
    ],
  },
  {
    year: "2022",
    months: [
      {
        month: "January",
        events: [
          {
            year: "2022",
            month: "January",
            date: "6 January",
            eventName:
              "NusantaraKU Scholarship Program - Charity Activities Light the Way to School",
            location:
              "Nusa Tenggara Barat (NTB), Nusa Tenggara Timur (NTT) dan papua",
            partner: "Runcing Foundation",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2022",
            month: "June",
            date: "24 June",
            eventName: "Fintech Lending Days",
            location: "Makassar",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "August",
        events: [
          {
            year: "2022",
            month: "August",
            date: "8 August",
            eventName:
              "Socialization of Financial Education and Literacy “Young Generation Can Do It All” ",
            location: "Medan",
            partner: "Akulaku Group",
          },
        ],
      },
    ],
  },
  {
    year: "2023",
    months: [
      {
        month: "March",
        events: [
          {
            year: "2023",
            month: "March",
            date: "14 March",
            eventName: "AFPI Goes To Campus",
            location: "Samarinda",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "April",
        events: [
          {
            year: "2023",
            month: "April",
            date: "11 April",
            eventName: "AFPI Goes To Campus",
            location: "Semarang",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "October",
        events: [
          {
            year: "2023",
            month: "October",
            date: "28 October",
            eventName:
              "CSR “A Year Older, A Forest Richer: AsetKu 1000 Mangrove Tree Planting” ",
            location: "Pulau Pari, Kepulauan Seribu",
            partner: "Lindungi Hutan",
          },
        ],
      },
    ],
  },
  {
    year: "2024",
    months: [
      {
        month: "May",
        events: [
          {
            year: "2024",
            month: "May",
            date: "6-7 May",
            eventName: "Fintech Lending Days",
            location: "Medan",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2024",
            month: "June",
            date: "27 June",
            eventName:
              "Talkshow with AsetKu 'Wisely Using Digital Financial Facilities'",
            location: "Bangka Belitung",
            partner: "HIPMI Pangkal Pinang",
          },
        ],
      },
      {
        month: "August",
        events: [
          {
            year: "2024",
            month: "August",
            date: "25 August",
            eventName:
              "Fintech Sport Days : Fun Walk 2024 Event #MerdekaDariPinjol",
            location: "Jakarta",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2024",
            month: "September",
            date: "27 September",
            eventName: "Hangout With AsetKu",
            location: "Anora, Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "October",
        events: [
          {
            year: "2024",
            month: "October",
            date: "21 October",
            eventName: "CSR Asetku 2024",
            location:
              "PAUD Pengharapan Sejati - Deli Serdang, Sumatera Utara, PAUD Agape Creative Children - Ungaran, Semarang, PAUD Betel Tumu Desa Oinlasi - Kab. Timur Tengah Selatan, NTT",
            partner: "OBI",
          },
          {
            year: "2024",
            month: "October",
            date: "23 October",
            eventName:
              "Financial Literacy for the Digitally Smart Generation: Wisely Utilizing Digital Financial Facilities",
            location: "PAUD Agape Creative Children - Ungaran, Semarang",
            partner: "OBI",
          },
        ],
      },
    ],
  },
]

export const EventActivityListId = [
  {
    year: "2018",
    months: [
      {
        month: "Agustus",
        events: [
          {
            year: "2018",
            month: "Agustus",
            date: "11 Agustus",
            eventName:
              "Pertemuan Pemberi Pinjaman AsetKu Jelajahi Dunia Layanan Keuangan Digital",
            location: "Bengawan Solo, Jakarta",
            partner: "Non",
          },
          {
            year: "2018",
            month: "Agustus",
            date: "20-25 Agustus",
            eventName:
              "Focus Group Discussion (FGD) dengan Pemberi Pinjaman Surabaya",
            location: "Non",
            partner: "Non",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2018",
            month: "September",
            date: "10-15 September",
            eventName:
              "Focus Group Discussion (FGD) dengan Pemberi Pinjaman Medan",
            location: "Non",
            partner: "Non",
          },
          {
            year: "2018",
            month: "September",
            date: "10-16 September",
            eventName:
              "Focus Group Discussion (FGD) dengan Pemberi Pinjaman Bandung",
            location: "Non",
            partner: "Non",
          },
        ],
      },

      {
        month: "Desember",
        events: [
          {
            year: "2018",
            month: "Desember",
            date: "15 Desember",
            eventName: "Tantangan & Peluang Dalam Fintech P2P Lending",
            location: "Surabaya",
            partner: "Non",
          },
        ],
      },
    ],
  },
  {
    year: "2019",
    months: [
      {
        month: "Januari",
        events: [
          {
            year: "2019",
            month: "Januari",
            date: "30 Januari",
            eventName: "Konferensi Akulaku Sahabatku",
            location: "Patio Jakarta",
            partner: "Akulaku",
          },
        ],
      },
      {
        month: "Februari",
        events: [
          {
            year: "2019",
            month: "Februari",
            date: "11 Februari",
            eventName: "Konferensi Pers Asetku #JelasBerkembang",
            location: "Ritz Carlton Pasific Place, Jakarta",
            partner: "Non",
          },
          {
            year: "2019",
            month: "Februari",
            date: "20 Februari",
            eventName: "Fintech Talk Vol.2 oleh Aftech",
            location: "Satrio Tower, Jakarta",
            partner: "Aftech",
          },
          {
            year: "2019",
            month: "Februari",
            date: "23 Februari",
            eventName:
              "Gerakan Peningkatan Inklusi Keuangan Indonesia Bersama Asetku",
            location: "JW Marriott, Medan",
            partner: "Non",
          },
          {
            year: "2019",
            month: "Februari",
            date: "27 Februari",
            eventName: "Tantangan & Peluang Dalam Fintech P2P Lending",
            location: "Binus University International, Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "Maret",
        events: [
          {
            year: "2019",
            month: "Maret",
            date: "8 Maret",
            eventName:
              "Akuntansi dalam Revolusi 4.0 untuk Mencapai SDGs 2030 di Accounting Fair 2019",
            location: "Universitas Bakrie, Jakarta",
            partner: "Non",
          },
          {
            year: "2019",
            month: "Maret",
            date: "",
            eventName: "Perkuat HIPMI 4.0 Didukung oleh Asetku",
            location: "Marbella Hotel, Bandung",
            partner: "HIPMI",
          },
          {
            year: "2019",
            month: "Maret",
            date: "16-17 Maret",
            eventName: "Sosialisasi Fintech Asetku #JelasBerkembang",
            location: "Bintaro X Change Tangerang - Akulaku KarnavAL",
            partner: "Akulaku",
          },
          {
            year: "2019",
            month: "Maret",
            date: "30 Maret",
            eventName:
              "Cara Mencapai Tujuan Keuangan Lebih Cepat di Era Digital bersama Finansialku",
            location: "Warunk Upnormal Tebet, Jakarta",
            partner: "Finansialku",
          },
        ],
      },
      {
        month: "April",
        events: [
          {
            year: "2019",
            month: "April",
            date: "10 April",
            eventName: "Jadi Millennial Cerdas di Era Digital Finance",
            location:
              "Gd. Aula Pasca Sarjana Universitas Diponegoro Jl. Hayam Wuruk Semarang",
            partner: "Non",
          },
          {
            year: "2019",
            month: "April",
            date: "13 April",
            eventName: "Perkembangan Fintech",
            location: "Atmajaya University",
            partner: "Non",
          },
          {
            year: "2019",
            month: "April",
            date: "25 April",
            eventName: "Memberdayakan UKM Indonesia dengan Teknologi",
            location: "The Kasablanka - Main Hall. Jakarta",
            partner: "Mekari",
          },
        ],
      },
      {
        month: "Mei",
        events: [
          {
            year: "2019",
            month: "Mei",
            date: "2-3 Mei",
            eventName: "Hari Fintech 2019",
            location: "Novotel Hotel Palembang",
            partner: "AFPI",
          },
          {
            year: "2019",
            month: "Mei",
            date: "8-11 Mei",
            eventName: "Ramadan Bersama Akulaku #AkuBisaBerbagi",
            location: "Senayan City, Jakarta",
            partner: "Akulaku",
          },
          {
            year: "2019",
            month: "Mei",
            date: "24-26 Mei",
            eventName: "ICS (Indonesian Cashless Society) 2019",
            location: "Summarecon Mall Bekasi",
            partner: "Non",
          },
          {
            year: "2019",
            month: "Mei",
            date: "25 Mei",
            eventName: "Instagram 3.0 - Zero To Millions",
            location: "Hotel Ibis Style Gajah Mada",
            partner: "Rintisan",
          },
        ],
      },
      {
        month: "Juni",
        events: [
          {
            year: "2019",
            month: "Juni",
            date: "25 Juni",
            eventName: "CGS-CIMB 13th Konferensi Tahunan Indonesia",
            location: "@ Ayana MidPlaza",
            partner: "Non",
          },
          {
            year: "2019",
            month: "Juni",
            date: "27 Juni",
            eventName: "Bagaimana merencanakan keuangan & danai di era digital",
            location: "@ C3 Jogjakarta Community",
            partner: "Finansialku",
          },
        ],
      },
      {
        month: "Juli",
        events: [
          {
            year: "2019",
            month: "Juli",
            date: "19 Juli",
            eventName: "Jadi Pengguna Cerdas di Era Digital Finance",
            location: "@ DILo Coworking Space Padang",
            partner: "Non",
          },
          {
            year: "2019",
            month: "Juli",
            date: "26 Juli",
            eventName: "Jadi Pengguna Cerdas di Era Digital Finance",
            location: "@ Meet Up Coworking Space Pekanbaru",
            partner: "Non",
          },
        ],
      },
      {
        month: "Agustus",
        events: [
          {
            year: "2019",
            month: "Agustus",
            date: "2 Agustus",
            eventName: "Fintech Fest 2019",
            location: "@ Four Points Manado",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2019",
            month: "September",
            date: "3-5 September",
            eventName: "Fintech Exhibition Fintech Day OJK Seminar",
            location:
              "@ BigMall Samarinda @Aston Samarinda Hotel & Convention Center",
            partner: "AFPI & OJK",
          },
          {
            year: "2019",
            month: "September",
            date: "8 September",
            eventName: "Millenials Dalam Revolusi Industri 4.0",
            location: "@ G2 Fakultas Ekonomi Universitas Negeri Surabaya",
            partner: "Universitas Negeri Surabaya",
          },
          {
            year: "2019",
            month: "September",
            date: "13 September",
            eventName:
              "Sosialisasi Jadi Pengguna Cerdas di Era Digital Finance",
            location: "@ Bali",
            partner: "Non",
          },
          {
            year: "2019",
            month: "September",
            date: "23-24 September",
            eventName: "Indonesia Fintech Submit & Expo 2019",
            location: "@ Jakarta Convention Center",
            partner: "Aftech",
          },
        ],
      },
      {
        month: "Oktober",
        events: [
          {
            year: "2019",
            month: "Oktober",
            date: "17-20 Oktober",
            eventName: "Finexpo & Sundown Run 2019",
            location: "@ Kota Kasablanka",
            partner: "Non",
          },
        ],
      },
      {
        month: "November",
        events: [
          {
            year: "2019",
            month: "November",
            date: "6-8 November",
            eventName: "Indonesia Fintech Show 2019",
            location: "@ Jakarta Convention Center",
            partner: "Tarsus",
          },
          {
            year: "2019",
            month: "November",
            date: "29 November 2019 - 1 Desember 2019",
            eventName: "Fintech Exhibition Surabaya 219",
            location: "@Convension Hall, Pakuwon Trade Center",
            partner: "AFPI",
          },
        ],
      },
    ],
  },
  {
    year: "2020",
    months: [
      {
        month: "Maret",
        events: [
          {
            year: "2020",
            month: "Maret",
            date: "10 Maret",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Gunung Jati, Cirebon",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "Juni",
        events: [
          {
            year: "2020",
            month: "Juni",
            date: "16 Juni",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Jambi, Jambi",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "Juli",
        events: [
          {
            year: "2020",
            month: "Juli",
            date: "9 Juli",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Tanjungpura, Pontianak",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "Agustus",
        events: [
          {
            year: "2020",
            month: "Agustus",
            date: "11 Agustus",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Bengkulu, Bengkulu",
            partner: "AFPI",
          },
          {
            year: "2020",
            month: "Agustus",
            date: "25 Agustus",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Halu Oleo, Kendari",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "Oktober",
        events: [
          {
            year: "2020",
            month: "Oktober",
            date: "6 Oktober",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Mataram, Mataram",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "November",
        events: [
          {
            year: "2020",
            month: "November",
            date: "17 November",
            eventName:
              "Fintech Indonesia Hadapi Resesi: Tantangan Berat atau Peluang Besar?",
            location: "Live via Youtube DailySocial",
            partner: "DailySocial",
          },
          {
            year: "2020",
            month: "November",
            date: "26 November",
            eventName: "AFPI Goes To Campus",
            location: "Bangka Belitung",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "Desember",
        events: [
          {
            year: "2020",
            month: "Desember",
            date: "3 Desember ",
            eventName:
              "AsetKu Literasi Event - Dunia P2P Lending dan Dunia Keuangan Setelah New Normal, Berbagai Kesempatan di Dalamnya",
            location: "Jambi",
            partner: "non",
          },
        ],
      },
    ],
  },
  {
    year: "2021",
    months: [
      {
        month: "Februari",
        events: [
          {
            year: "2021",
            month: "Februari",
            date: "24 Februari",
            eventName: "AFPI Goes To Campus",
            location: "Lampung",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "Februari",
            date: "26 Februari",
            eventName: "AFPI Goes To Campus",
            location: "Bengkulu",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "April",
        events: [
          {
            year: "2021",
            month: "April",
            date: "30 April",
            eventName: "AFPI Goes To Campus",
            location: "Batam",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "Mei",
        events: [
          {
            year: "2021",
            month: "Mei",
            date: "28 Mei",
            eventName: "AFPI Goes To Campus",
            location: "Ambon",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "Mei",
            date: "31 Mei",
            eventName: "AFPI Goes To Campus",
            location: "Salatiga",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "Mei",
            date: "",
            eventName: "Proyek CSR 1 Bulan Ramadhan 2021 | Mei 2021",
            location: "Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "Juni",
        events: [
          {
            year: "2021",
            month: "Juni",
            date: "30 Juni",
            eventName: "AFPI Goes To Campus",
            location: "Jember",
            partner: "AFPI",
          },
        ],
      },
    ],
  },
  {
    year: "2022",
    months: [
      {
        month: "Januari",
        events: [
          {
            year: "2022",
            month: "Januari",
            date: "6 Januari",
            eventName:
              "Program Beasiswa NusantaraKU - Kegiatan Amal Terangi Jalan Menuju Sekolah",
            location:
              "Nusa Tenggara Barat (NTB), Nusa Tenggara Timur (NTT) dan papua",
            partner: "Runcing Foundation",
          },
        ],
      },
      {
        month: "Juni",
        events: [
          {
            year: "2022",
            month: "Juni",
            date: "24 Juni",
            eventName: "Hari Fintech Lending",
            location: "Makassar",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "Agustus",
        events: [
          {
            year: "2022",
            month: "Agustus",
            date: "8 Agustus",
            eventName:
              "Sosialisasi Edukasi dan Literasi Keuangan “Generasi Muda Semua Bisa”",
            location: "Medan",
            partner: "Akulaku Group",
          },
        ],
      },
    ],
  },
  {
    year: "2023",
    months: [
      {
        month: "Maret",
        events: [
          {
            year: "2023",
            month: "Maret",
            date: "14 Maret",
            eventName: "AFPI Goes To Campus",
            location: "Samarinda",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "April",
        events: [
          {
            year: "2023",
            month: "April",
            date: "11 April",
            eventName: "AFPI Goes To Campus",
            location: "Semarang",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "Oktober",
        events: [
          {
            year: "2023",
            month: "Oktober",
            date: "28 Oktober",
            eventName:
              "CSR “Setahun Bertambah, Hutan Makin Kaya: AsetKu Tanam 1000 Pohon Mangrove”",
            location: "Pulau Pari, Kepulauan Seribu",
            partner: "Lindungi Hutan",
          },
        ],
      },
    ],
  },
  {
    year: "2024",
    months: [
      {
        month: "Mei",
        events: [
          {
            year: "2024",
            month: "Mei",
            date: "6-7 Mei",
            eventName: "Hari Fintech Lending",
            location: "Hotel Santika Dyandra Medan",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "Juni",
        events: [
          {
            year: "2024",
            month: "Juni",
            date: "27 Juni",
            eventName:
              "Bincang Bareng AsetKu 'Bijak Memanfaatkan Fasilitas Keuangan Digital'",
            location: "Universitas Muhammadiyah Bangka Belitung",
            partner: "HIPMI Pangkal Pinang",
          },
        ],
      },
      {
        month: "Agustus",
        events: [
          {
            year: "2024",
            month: "Agustus",
            date: "25 Agustus",
            eventName:
              "Fintech Sport Days : Acara Fun Walk 2024 #MerdekaDariPinjol",
            location: "Jakarta",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2024",
            month: "September",
            date: "27 September",
            eventName: "Nongki Bareng Asetku",
            location: "Anora, Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "Oktober",
        events: [
          {
            year: "2024",
            month: "Oktober",
            date: "21 Oktober",
            eventName: "CSR Asetku 2024",
            location:
              "PAUD Pengharapan Sejati - Deli Serdang, Sumatera Utara, PAUD Agape Creative Children - Ungaran, Semarang, PAUD Betel Tumu Desa Oinlasi - Kab. Timur Tengah Selatan, NTT",
            partner: "OBI",
          },
          {
            year: "2024",
            month: "Oktober",
            date: "23 Oktober",
            eventName:
              "Literasi Finansial untuk Generasi Cerdas Digital : Bijak Memanfaatkan Fasilitas Keuangan Digital",
            location: "PAUD Agape Creative Children - Ungaran, Semarang",
            partner: "OBI",
          },
        ],
      },
    ],
  },
]
