import React, { useState } from "react"
// Import Swiper React components

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"

import "./bod.scss"
import { useTranslation } from "react-i18next"
import { withTrans } from "../../i18n/withTrans"
import { StaticImage } from "gatsby-plugin-image"
import { Grid } from "@material-ui/core"

function Bod() {
  const { t } = useTranslation()
  const [bodActiveIndex, setBodActiveIndex] = useState(0)

  const bodImg1 = "../../assets/images/profile-bod/new-bod-1.png"
  const bodImg2 = "../../assets/images/profile-bod/new-bod-2.png"
  const bodImg3 = "../../assets/images/profile-bod/new-bod-3.png"
  const bodImg4 = "../../assets/images/profile-bod/new-bod-4.png"
  const bodImg5 = "../../assets/images/profile-bod/new-bod-5.png"

  const bodList = [
    {
      name: "Kim Sam",
      position: t("about-us.president-director"),
      desc: t(`about-us.bod-2`),
    },
    {
      name: "Zainal Arifin",
      position: t("about-us.director"),
      desc: t(`about-us.bod-3`),
    },
    {
      name: "Ferdinand L.Tobing",
      position: t("about-us.commissioner"),
      desc: t(`about-us.bod-4`),
    },
    {
      name: "PT. Akulaku Silvrr Indonesia",
      position: t("about-us.shareholder"),
      desc: t(`about-us.akulaku`),
    },
    {
      name: "Martha",
      position: t("about-us.shareholder"),
      desc: t(`about-us.bod-5`),
    },
  ]

  return (
    <>
      <div className="bod-desktop">
        <div className="title">{t(`about-us.profile-title`)}</div>
        <div className="bod-card">
          <div className="bod-description">
            <div className="name-desc">
              <div className="name">{bodList[bodActiveIndex].name}</div>
              <div className="job-title">
                {bodList[bodActiveIndex].position}
              </div>
              <div className="desc">{bodList[bodActiveIndex].desc}</div>
            </div>
            <div className="bod-list">
              <div
                className="person-li"
                key={0}
                onClick={() => {
                  setBodActiveIndex(0)
                }}
              >
                <div className="bod-img">
                  <StaticImage
                    src="../../assets/images/decoration/photo-dark.svg"
                    className={`dark-effect ${
                      bodActiveIndex == 0 ? "active" : ""
                    }`}
                    alt="bod"
                  />
                  <StaticImage
                    src="../../assets/images/profile-bod/mini-bod-1.png"
                    alt="bod"
                    className={`bod-photo ${
                      bodActiveIndex == 0 ? "active" : ""
                    }`}
                  />
                </div>
                <div className="desc">
                  <p className="name">Kim Sam</p>
                  <p className="position">{t(`about-us.president-director`)}</p>
                </div>
              </div>
              <div
                className="person-li"
                key={1}
                onClick={() => {
                  setBodActiveIndex(1)
                }}
              >
                <div className="bod-img">
                  <StaticImage
                    src="../../assets/images/decoration/photo-dark.svg"
                    alt="bod"
                    className={`dark-effect ${
                      bodActiveIndex == 1 ? "active" : ""
                    }`}
                  />
                  <StaticImage
                    src="../../assets/images/profile-bod/mini-bod-2.png"
                    alt="bod"
                    className={`bod-photo ${
                      bodActiveIndex == 1 ? "active" : ""
                    }`}
                  />
                </div>
                <div className="desc">
                  <p className="name">Zainal Arifin</p>
                  <p className="position">{t(`about-us.director`)}</p>
                </div>
              </div>
              <div
                className="person-li"
                key={2}
                onClick={() => {
                  setBodActiveIndex(2)
                }}
              >
                <div className="bod-img">
                  <StaticImage
                    src="../../assets/images/decoration/photo-dark.svg"
                    alt="bod"
                    className={`dark-effect ${
                      bodActiveIndex == 2 ? "active" : ""
                    }`}
                  />
                  <StaticImage
                    src="../../assets/images/profile-bod/mini-bod-3.png"
                    alt="bod"
                    className={`bod-photo ${
                      bodActiveIndex == 2 ? "active" : ""
                    }`}
                  />
                </div>
                <div className="desc">
                  <p className="name">Ferdinand L.Tobing</p>
                  <p className="position">{t(`about-us.commissioner`)}</p>
                </div>
              </div>
              <div
                className="person-li"
                key={3}
                onClick={() => {
                  setBodActiveIndex(3)
                }}
              >
                <div className="bod-img">
                  <StaticImage
                    src="../../assets/images/decoration/photo-dark.svg"
                    alt="bod"
                    className={`dark-effect ${
                      bodActiveIndex == 3 ? "active" : ""
                    }`}
                  />
                  <StaticImage
                    src="../../assets/images/profile-bod/mini-bod-4.png"
                    alt="bod"
                    className={`bod-photo ${
                      bodActiveIndex == 3 ? "active" : ""
                    }`}
                  />
                </div>
                <div className="desc">
                  <p className="name">PT. Akulaku Silvrr Indonesia</p>
                  <p className="position">{t("about-us.shareholder")}</p>
                </div>
              </div>
              <div
                className="person-li"
                key={4}
                onClick={() => {
                  setBodActiveIndex(4)
                }}
              >
                <div className="bod-img">
                  <StaticImage
                    src="../../assets/images/decoration/photo-dark.svg"
                    alt="bod"
                    className={`dark-effect ${
                      bodActiveIndex == 4 ? "active" : ""
                    }`}
                  />
                  <StaticImage
                    src="../../assets/images/profile-bod/mini-bod-5.png"
                    alt="bod"
                    className={`bod-photo ${
                      bodActiveIndex == 4 ? "active" : ""
                    }`}
                  />
                </div>
                <div className="desc">
                  <p className="name">Martha</p>
                  <p className="position">{t("about-us.shareholder")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="large-picture">
            <div className="bod-img-large">
              <StaticImage
                src={bodImg1}
                className={`bod-img ${bodActiveIndex == 0 ? "active" : ""}`}
              />
              <StaticImage
                src={bodImg2}
                className={`bod-img ${bodActiveIndex == 1 ? "active" : ""}`}
              />
              <StaticImage
                src={bodImg3}
                className={`bod-img ${bodActiveIndex == 2 ? "active" : ""}`}
              />
              <StaticImage
                src={bodImg4}
                className={`bod-img ${bodActiveIndex == 3 ? "active" : ""}`}
              />
              <StaticImage
                src={bodImg5}
                className={`bod-img ${bodActiveIndex == 4 ? "active" : ""}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="management-list">
        <div className="head-list">
          <div className="people-container" key={0}>
            <StaticImage
              src={"../../assets/images/profile-management/head-1.png"}
              className="people-img"
            />
            <div className="name">{headList[0].name}</div>
            <div className="position">{headList[0].position}</div>
          </div>
          <div className="people-container" key={1}>
            <StaticImage
              src={"../../assets/images/profile-management/head-3.png"}
              className="people-img"
            />
            <div className="name">{headList[1].name}</div>
            <div className="position">{headList[1].position}</div>
          </div>
        </div>
        <Grid container className="manager-list">
          <Grid item md={2} className="people-container" key={0}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-1.png"}
              className="people-img"
            />
            <div className="name">{managementList[0].name}</div>
            <div className="position">{managementList[0].position}</div>
          </Grid>
          <Grid item md={2} className="people-container" key={1}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-2.png"}
              className="people-img"
            />
            <div className="name">{managementList[1].name}</div>
            <div className="position">{managementList[1].position}</div>
          </Grid>
          <Grid item md={2} className="people-container" key={2}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-3.png"}
              className="people-img"
            />
            <div className="name">{managementList[2].name}</div>
            <div className="position">{managementList[2].position}</div>
          </Grid>
          <Grid item md={2} className="people-container" key={3}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-4.png"}
              className="people-img"
            />
            <div className="name">{managementList[3].name}</div>
            <div className="position">{managementList[3].position}</div>
          </Grid>

          <Grid item md={2} className="people-container" key={4}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-5.png"}
              className="people-img"
            />
            <div className="name">{managementList[4].name}</div>
            <div className="position">{managementList[4].position}</div>
          </Grid>
          <Grid item md={3} className="people-container" key={5}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-6.png"}
              className="people-img"
            />
            <div className="name">{managementList[5].name}</div>
            <div className="position">{managementList[5].position}</div>
          </Grid>
          <Grid item md={3} className="people-container" key={6}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-7.png"}
              className="people-img"
            />
            <div className="name">{managementList[6].name}</div>
            <div className="position">{managementList[6].position}</div>
          </Grid>
          <Grid item md={3} className="people-container" key={7}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-8.png"}
              className="people-img"
            />
            <div className="name">{managementList[7].name}</div>
            <div className="position">{managementList[7].position}</div>
          </Grid>
          <Grid item md={3} className="people-container" key={8}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-9.png"}
              className="people-img"
            />
            <div className="name">{managementList[8].name}</div>
            <div className="position">{managementList[8].position}</div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const headList = [
  { name: "Diana Romatua Marbun", position: "Head of HR & GA" },
  { name: "Ng Sui An", position: "Head of Risk Management" },
]

const managementList = [
  { name: "Setyanto Anggara Putra", position: "Engineering Manager" },
  { name: "Edy Setiawan", position: "Product Team Lead" },
  { name: "Dani Royman Simanjuntak", position: "IT Infra & Support Lead" },
  { name: "Ben Derajadi", position: "Legal Compliance Manager" },
  { name: "Deshila Alifannisa Dyoga", position: "Customer Service Team Lead" },
  {
    name: "Barry Evan Sumantri",
    position: "Event & Government Relations Manager",
  },
  { name: "Waldes Hagambuala Wau", position: "BD & Partnership Manager" },
  { name: "Septian Nugraha Cahya Putra", position: "Internal Audit Manager" },
  { name: "Agus Djoko Sampurna", position: "Collection Manager" },
]

export default withTrans(Bod)
