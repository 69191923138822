import React, { useState } from "react"
import "./bodPhone.scss"
import { useTranslation } from "react-i18next"
import { withTrans } from "../../i18n/withTrans"
import { StaticImage } from "gatsby-plugin-image"
import { Grid } from "@material-ui/core"

function BodPhone() {
  const { t } = useTranslation()
  const [activeBOD, setActiveBOD] = useState(0)

  const bodImg1 = "../../assets/images/profile-bod/new-bod-1.png"
  const bodImg2 = "../../assets/images/profile-bod/new-bod-2.png"
  const bodImg3 = "../../assets/images/profile-bod/new-bod-3.png"
  const bodImg4 = "../../assets/images/profile-bod/new-bod-4.png"
  const bodImg5 = "../../assets/images/profile-bod/new-bod-5.png"

  const bodList = [
    {
      name: "Kim Sam",
      position: t("about-us.president-director"),
      desc: t(`about-us.bod-2`),
    },
    {
      name: "Zainal Arifin",
      position: t("about-us.director"),
      desc: t(`about-us.bod-3`),
    },
    {
      name: "Ferdinand L.Tobing",
      position: t("about-us.commissioner"),
      desc: t(`about-us.bod-4`),
    },
    {
      name: "PT. Akulaku Silvrr Indonesia",
      position: t("about-us.shareholder"),
      desc: t(`about-us.akulaku`),
    },
    {
      name: "Martha",
      position: t("about-us.shareholder"),
      desc: t(`about-us.bod-5`),
    },
  ]

  return (
    <div className="bod-phone-container">
      <div className="title">{t(`about-us.profile-title`)}</div>

      <div className="bod-desc-section">
        <div className="bod-mini-list">
          <div
            key={0}
            className={`mini-profile-box ${activeBOD == 0 ? "active" : ""}`}
            onClick={() => {
              setActiveBOD(0)
            }}
          >
            <StaticImage
              src={"../../assets/images/profile-bod/mini-bod-1.png"}
              className="bod-mini-profile"
            />
          </div>
          <div
            key={1}
            className={`mini-profile-box ${activeBOD == 1 ? "active" : ""}`}
            onClick={() => {
              setActiveBOD(1)
            }}
          >
            <StaticImage
              src={"../../assets/images/profile-bod/mini-bod-2.png"}
              className="bod-mini-profile"
            />
          </div>
          <div
            key={2}
            className={`mini-profile-box ${activeBOD == 2 ? "active" : ""}`}
            onClick={() => {
              setActiveBOD(2)
            }}
          >
            <StaticImage
              src={"../../assets/images/profile-bod/mini-bod-3.png"}
              className="bod-mini-profile"
            />
          </div>
          <div
            key={3}
            className={`mini-profile-box ${activeBOD == 3 ? "active" : ""}`}
            onClick={() => {
              setActiveBOD(3)
            }}
          >
            <StaticImage
              src={"../../assets/images/profile-bod/mini-bod-4.png"}
              className="bod-mini-profile"
            />
          </div>
          <div
            key={4}
            className={`mini-profile-box ${activeBOD == 4 ? "active" : ""}`}
            onClick={() => {
              setActiveBOD(4)
            }}
          >
            <StaticImage
              src={"../../assets/images/profile-bod/mini-bod-5.png"}
              className="bod-mini-profile"
            />
          </div>
        </div>
        <div className="bod-detail-container">
          <div
            key={0}
            className={`bod-detail ${activeBOD == 0 ? "active" : ""}`}
          >
            <div className="bod-img-large">
              <StaticImage src={bodImg1} className="bod-img" />
            </div>
            <div className="name-title">
              <div className="name">{bodList[0].name}</div>
              <div className="title">{bodList[0].position}</div>
            </div>
            <div className="bod-desc">{bodList[0].desc}</div>
          </div>
          <div
            key={1}
            className={`bod-detail ${activeBOD == 1 ? "active" : ""}`}
          >
            <div className="bod-img-large">
              <StaticImage src={bodImg2} className="bod-img" />
            </div>
            <div className="name-title">
              <div className="name">{bodList[1].name}</div>
              <div className="title">{bodList[1].position}</div>
            </div>
            <div className="bod-desc">{bodList[1].desc}</div>
          </div>
          <div
            key={2}
            className={`bod-detail ${activeBOD == 2 ? "active" : ""}`}
          >
            <div className="bod-img-large">
              <StaticImage src={bodImg3} className="bod-img" />
            </div>
            <div className="name-title">
              <div className="name">{bodList[2].name}</div>
              <div className="title">{bodList[2].position}</div>
            </div>
            <div className="bod-desc">{bodList[2].desc}</div>
          </div>
          <div
            key={3}
            className={`bod-detail ${activeBOD == 3 ? "active" : ""}`}
          >
            <div className="bod-img-large">
              <StaticImage src={bodImg4} className="bod-img" />
            </div>
            <div className="name-title">
              <div className="name">{bodList[3].name}</div>
              <div className="title">{bodList[3].position}</div>
            </div>
            <div className="bod-desc">{bodList[3].desc}</div>
          </div>
          <div
            key={4}
            className={`bod-detail ${activeBOD == 4 ? "active" : ""}`}
          >
            <div className="bod-img-large">
              <StaticImage src={bodImg5} className="bod-img" />
            </div>
            <div className="name-title">
              <div className="name">{bodList[4].name}</div>
              <div className="title">{bodList[4].position}</div>
            </div>
            <div className="bod-desc">{bodList[4].desc}</div>
          </div>
        </div>
      </div>
      <div className="management-list-phone">
        <Grid container className="manager-list">
          <Grid item xs={4} className="people-container" key={0}>
            <StaticImage
              src={"../../assets/images/profile-management/head-1.png"}
              className="people-img"
            />
            <div className="name">{headList[0].name}</div>
            <div className="position">{headList[0].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={1}>
            <StaticImage
              src={"../../assets/images/profile-management/head-3.png"}
              className="people-img"
            />
            <div className="name">{headList[1].name}</div>
            <div className="position">{headList[1].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={3}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-1.png"}
              className="people-img"
            />
            <div className="name">{managementList[0].name}</div>
            <div className="position">{managementList[0].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={4}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-2.png"}
              className="people-img"
            />
            <div className="name">{managementList[1].name}</div>
            <div className="position">{managementList[1].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={5}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-3.png"}
              className="people-img"
            />
            <div className="name">{managementList[2].name}</div>
            <div className="position">{managementList[2].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={6}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-4.png"}
              className="people-img"
            />
            <div className="name">{managementList[3].name}</div>
            <div className="position">{managementList[3].position}</div>
          </Grid>

          <Grid item xs={4} className="people-container" key={7}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-5.png"}
              className="people-img"
            />
            <div className="name">{managementList[4].name}</div>
            <div className="position">{managementList[4].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={8}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-6.png"}
              className="people-img"
            />
            <div className="name">{managementList[5].name}</div>
            <div className="position">{managementList[5].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={9}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-7.png"}
              className="people-img"
            />
            <div className="name">{managementList[6].name}</div>
            <div className="position">{managementList[6].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={10}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-8.png"}
              className="people-img"
            />
            <div className="name">{managementList[7].name}</div>
            <div className="position">{managementList[7].position}</div>
          </Grid>
          <Grid item xs={4} className="people-container" key={11}>
            <StaticImage
              src={"../../assets/images/profile-management/manager-9.png"}
              className="people-img"
            />
            <div className="name">{managementList[8].name}</div>
            <div className="position">{managementList[8].position}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const headList = [
  { name: "Diana Romatua Marbun", position: "Head of HR & GA" },
  { name: "Ng Sui An", position: "Head of Risk Management" },
]

const managementList = [
  { name: "Setyanto Anggara Putra", position: "Engineering Manager" },
  { name: "Edy Setiawan", position: "Product Team Lead" },
  { name: "Dani Royman Simanjuntak", position: "IT Infra & Support Lead" },
  { name: "Ben Derajadi", position: "Legal Compliance Manager" },
  { name: "Deshila Alifannisa Dyoga", position: "Customer Service Team Lead" },
  {
    name: "Barry Evan Sumantri",
    position: "Event & Government Relations Manager",
  },
  { name: "Waldes Hagambuala Wau", position: "BD & Partnership Manager" },
  { name: "Septian Nugraha Cahya Putra", position: "Internal Audit Manager" },
  { name: "Agus Djoko Sampurna", position: "Collection Manager" },
]

export default withTrans(BodPhone)
